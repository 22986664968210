import React from "react";

import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  ListGroup,
  Table,
  Badge,
  Card,
} from "react-bootstrap";
import { useState } from "react";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import useSerialPort from "../../utils/SerialPort";
import {
  useAddDispatchRegMutation,
  useGetDispatchRegQuery,
} from "../../slices/dispatchRegSlice";

const DispatchScreen = () => {
  const initialFormState = {
    challanNo: "",
    challanDate: "",
    partyCode: "",
    partyName: "",
    partyPlace: "",
    orderAmt: "",
    transportName: "",
    salesRep: "",
    orderStatus: "",
  };
  const [dispatchForm, setDispatchForm] = useState({ initialFormState });
  const [isMannualEntry, setIsMannualEntry] = useState(false);
  const [toSort, setSort] = useState({ keyToSort: "challanNo", order: "desc" });
  const [search, setSearch] = useState("");
  const { connectToSerialPort, serialData, connected } = useSerialPort();
  const { data, isLoading, error, refetch } = useGetDispatchRegQuery();
  //const [scanData, setScanData] = useState("");

  const [addDispatchReg, { isLoading: LoadingCeate }] =
    useAddDispatchRegMutation();

  const headers = [
    { key: "chllanNo", label: "Challan No" },
    { key: "challanDate", label: "Challan Date" },
    { key: "partyName", label: "Party Name" },
    { key: "partyPlace", label: "Party Place" },
    { key: "orderAmt", label: "Order Amt" },
    { key: "tags", label: "Tags" },
    { key: "orderStatus", label: "Order Status" },
    { key: "action", label: "Action" },
  ];

  const cleanInput = (input) => {
    return input
      .replace(/\[/g, "{") // Replace opening square brackets with curly braces
      .replace(/\]/g, "}") // Replace closing square brackets with curly braces
      .replace(/Shift|ArrowDown/g, "") // Remove unwanted keys
      .replace(/(\s){2,}/g, " ") // Remove extra spaces
      .trim() // Remove leading and trailing spaces
      .toUpperCase(); // Convert to uppercase
  };
  const handelScan = async (e) => {
    if (e.key === "Enter") {
      const cleanedInput = cleanInput(e.target.value);

      if (cleanedInput.includes("{") && cleanedInput.includes("}")) {
        try {
          const parsedData = JSON.parse(cleanedInput);

          if (!parsedData.TN || !parsedData.DN) {
            toast.error("Invalid JSON format");
          } else {
            if (data.some((item) => item.challanNo === parsedData.DN.trim())) {
              setSearch(parsedData.PN.trim());
              toast.error("Challan Number already exist");
              e.target.value = "";
            } else {
              console.log("dispatchForm", dispatchForm);
              await addDispatchReg({
                challanNo: parsedData.DN.trim(),
                partyCode: parsedData.PC.trim(),
                partyName: parsedData.PN.trim(),
                partyPlace: parsedData.STN.trim(),
                transportName: parsedData.TN.trim(),
                orderStatus: "Printed",
              }).unwrap();
              refetch();
              toast.success("Record Added Successfully");
              e.target.value = "";
            }
          }
        } catch (error) {
          e.target.value = "";
          toast.error(`Not a valid JSON format:${error}`); // Error message
        }
      } else {
        e.target.value = "";
        toast.error("Enter Scan Data");
      }
    }
  };

  function handleSort(key) {
    if (toSort.keyToSort === key) {
      setSort({
        keyToSort: key,
        order: toSort.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSort({ keyToSort: key, order: "asc" });
    }
  }

  const sortData = (data) => {
    const sortedData = [...data].sort((a, b) => {
      if (a[toSort.keyToSort] < b[toSort.keyToSort]) {
        return toSort.order === "asc" ? -1 : 1;
      }
      if (a[toSort.keyToSort] > b[toSort.keyToSort]) {
        return toSort.order === "asc" ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  };

  const sumbitHandler = async () => {
    try {
      if (!dispatchForm.challanNo) {
        toast.error("Challan Number is required");
        return;
      } else {
        await addDispatchReg(dispatchForm).unwrap();

        refetch();
        toast.success("Record Added Successfully");
      }
      setDispatchForm(initialFormState);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Container>
            {serialData || "No data received."}
            {isMannualEntry ? (
              <ListGroup>
                <h5>Enter Challan Detials </h5>
                <ListGroup.Item>
                  <Form>
                    <Form.Group className="mb-3" controlId="disptachForm">
                      <Row>
                        <Col md={2}>
                          <Form.Label>Challan Number</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Challan Number"
                            value={dispatchForm.challanNo}
                            onChange={(e) =>
                              setDispatchForm({
                                ...dispatchForm,
                                challanNo: e.target.value,
                              })
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Form.Label>Challan Date</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Enter Challan Date"
                            value={dispatchForm.challanDate}
                            onChange={(e) =>
                              setDispatchForm({
                                ...dispatchForm,
                                challanDate: e.target.value,
                              })
                            }
                          />
                        </Col>
                        <Col md={4}>
                          <Form.Label>Party Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Party Name"
                            value={dispatchForm.partyName}
                            onChange={(e) =>
                              setDispatchForm({
                                ...dispatchForm,
                                partyName: e.target.value,
                              })
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Form.Label>Party Place</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Party Place"
                            value={dispatchForm.partyPlace}
                            onChange={(e) =>
                              setDispatchForm({
                                ...dispatchForm,
                                partyPlace: e.target.value,
                              })
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Form.Label>Order Amount</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Order Amount"
                            value={dispatchForm.orderAmt}
                            onChange={(e) =>
                              setDispatchForm({
                                ...dispatchForm,
                                orderAmt: e.target.value,
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    {LoadingCeate && <Loader />}
                    <div>
                      <Button
                        onClick={sumbitHandler}
                        variant="primary"
                        className="me-3"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={() => setDispatchForm(initialFormState)}
                        variant="warning"
                        className="me-3"
                      >
                        Reset
                      </Button>

                      <Button
                        onClick={() => {
                          setIsMannualEntry(false);
                          setDispatchForm(initialFormState);
                        }}
                        variant="secondary"
                      >
                        Scan Option
                      </Button>

                      {error && (
                        <Message variant="danger">
                          {error?.data?.message}
                        </Message>
                      )}
                    </div>
                  </Form>
                </ListGroup.Item>
              </ListGroup>
            ) : (
              <Card className="mb-3">
                <Row>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      className="m-3"
                      placeholder="Please Scan Here"
                      onKeyDown={handelScan}
                    />
                  </Col>
                  <Col md={1}>
                    <Button
                      variant={connected ? "success" : "danger"}
                      className="m-3"
                      onClick={connectToSerialPort}
                      disabled={connected}
                    >
                      {connected ? "Connected" : "Connect "}
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      variant="secondary"
                      className="m-3"
                      onClick={() => setIsMannualEntry(true)}
                    >
                      Mannual Entry
                    </Button>
                  </Col>
                </Row>
              </Card>
            )}
          </Container>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col md={6}>
              <h4>Distach Registry</h4>
            </Col>
            <Col md={6}>
              <div style={{ position: "relative" }}>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="mb-2"
                  style={{ paddingRight: "30px" }}
                />
                {search && (
                  <ImCross
                    onClick={() => setSearch("")}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#888",
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error?.data?.message}</Message>
          ) : (
            <Table striped bordered hover responsive className="table-sm">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header.key} onClick={() => handleSort(header.key)}>
                      <span>{header.label}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortData(data)
                  .filter((item) => {
                    return search.toLowerCase() === ""
                      ? item
                      : item.partyName
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                          item.challanNo
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          item.partyPlace
                            .toLowerCase()
                            .includes(search.toLowerCase());
                  })
                  .map((dispatch) => (
                    <tr key={dispatch.id}>
                      <td>{dispatch.challanNo}</td>
                      <td>
                        {dispatch.challanDate === "" &&
                          new Date(dispatch.challanDate).toLocaleDateString(
                            "en-GB"
                          )}
                      </td>
                      <td>{dispatch.partyName}</td>
                      <td>{dispatch.partyPlace}</td>
                      <td>{dispatch.orderAmt}</td>
                      <td>
                        {dispatch.priority ? (
                          <Badge bg="danger">Ugrent</Badge>
                        ) : (
                          ""
                        )}{" "}
                      </td>
                      <td>{dispatch.orderStatus}</td>
                      <td>
                        <Button
                          type="button"
                          variant="light"
                          as={Link}
                          to={`/dispatch/${dispatch.id}`}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DispatchScreen;
