import React from 'react'
import {Table, Button,Row,Col} from 'react-bootstrap'
import{FaEdit,FaTrash } from 'react-icons/fa'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useGetProductsQuery,useCreateProductMutation } from '../../slices/productsApiSlice'
import { toast } from 'react-toastify'
import { LinkContainer } from 'react-router-bootstrap'

const ProductListScreen = () => {
    const {data:products, isLoading, error, refetch} = useGetProductsQuery();

    const [createProduct, {isLoading:loadinCreate}] = useCreateProductMutation();

    const deleteHandler = (id) => {
        console.log(id);
    }

    const createProductHandler = async () => {
        if (window.confirm('Are you sure you want to create a new product?')) {
            try {
                await createProduct();
                refetch();
                toast.success('Product created successfully');
            } catch (error) {
                toast.error(error?.data?.message || error.error);
            }
        }
    }
  return (
    <>
        <Row className='align-items-center'>
            <Col>
                <h1>Products</h1>
            </Col>
            <Col className='text-end'>
                <Button className='btn-sm m-3' onClick={createProductHandler}>
                   <FaEdit/> Create Product
                </Button>
            </Col>
        </Row>
        {loadinCreate && <Loader/>}
        {isLoading ? <Loader/> : error ? <Message variant='danger'>
        {error} </Message> : (
            <>
                <Table striped hover responsive className='table-sm'>
                    <thead>
                        <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>PRICE</th>
                        <th>CATEGORY</th>
                        <th>BRAND</th>
                        <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {products.map((product) => (
                            <tr key={product.id}>
                                <td>{product.id}</td>
                                <td>{product.name}</td>
                                <td>${product.price}</td>
                                <td>{product.category}</td>
                                <td>{product.brand}</td>
                                <td>
                                    <LinkContainer to={`/admin/product/${product.id}/edit`}>
                                        <Button variant='light' className='btn-sm mx-2'>
                                            <FaEdit/>
                                        </Button>
                                    </LinkContainer>
                                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(product.id)}>
                                        <FaTrash style={{color:'white'}}/>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </>
        )}
        
    </>
  )
}

export default ProductListScreen