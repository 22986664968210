import { apiSlice } from "./apiSlice";
import { DISPATCHREG_URL } from "../constants";

export const dispatchRegApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addDispatchReg: builder.mutation({
      query: (data) => ({
        url: DISPATCHREG_URL,
        method: "POST",
        body: data,
      }),
    }),
    getDispatchReg: builder.query({
      query: () => ({
        url: DISPATCHREG_URL,
        method: "GET",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["DispatchReg"],
    }),
    getDispatchDetails: builder.query({
      query: (id) => ({
        url: `${DISPATCHREG_URL}/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["DispatchReg"],
    }),
    updateDispatchReg: builder.mutation({
      query: (data) => ({
        url: `${DISPATCHREG_URL}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["DispatchReg"],
    }),
    getPartydetails: builder.query({
      query: (id) => ({
        url: `${DISPATCHREG_URL}/party/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["DispatchReg"],
    }),
  }),
});

export const {
  useAddDispatchRegMutation,
  useGetDispatchRegQuery,
  useGetDispatchDetailsQuery,
  useUpdateDispatchRegMutation,
} = dispatchRegApiSlice;
