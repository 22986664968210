//export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : '';

export const BASE_URL = "";
export const PRODUCTS_URL = "/api/products";
export const CART_URL = "/api/cart";
export const ORDERS_URL = "/api/orders";
export const USER_URL = "/api/users";
export const PAYPAL_URL = "/api/config/paypal";
export const UPLOAD_URL = "/api/upload";
export const DISPATCHREG_URL = "/api/dispatchReg";
