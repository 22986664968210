import { ListGroupItem } from "react-bootstrap";
import React from "react";
import { ListGroup, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormContainer from "../components/FormContainer.jsx";

const HomeScreen = () => {
  return (
    <>
      <h1>Home Screen</h1>
      <FormContainer>
        <ListGroup className="pd-3 mt-3">
          <h4 style={{ padding: "10px" }}>Dispatch Management</h4>
          <ListGroup.Item as={Link} to="/dispatch">
            Order Registry{" "}
          </ListGroup.Item>
          <ListGroupItem> Outbound Registry</ListGroupItem>
        </ListGroup>
        <ListGroup className="pd-3 mt-3">
          <h4 style={{ padding: "10px" }}>Marketing Tools</h4>
          <ListGroup.Item>
            CRM <Badge bg="secondary">Coming Soon</Badge>
          </ListGroup.Item>
          <ListGroup.Item>
            Whatsapp API <Badge bg="secondary">Coming Soon</Badge>
          </ListGroup.Item>
          <ListGroup.Item>
            Product Cataglogue <Badge bg="secondary">Coming Soon</Badge>
          </ListGroup.Item>
          <ListGroup.Item>
            Outstanding Reminder <Badge bg="secondary">Coming Soon</Badge>
          </ListGroup.Item>
        </ListGroup>
      </FormContainer>
    </>
  );
};

export default HomeScreen;
