import React from "react";
import { useParams, Link } from "react-router-dom";
import {
  useGetDispatchDetailsQuery,
  useUpdateDispatchRegMutation,
} from "../../slices/dispatchRegSlice";
import { toast } from "react-toastify";
import { ListGroup, Row, Col, Form, Button, Badge } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

import { FaTrashAlt } from "react-icons/fa";
const DispatchDetailScreen = () => {
  const { id: dispatchId } = useParams();
  //const navigate = useNavigate();
  const { data, isLoading, error } = useGetDispatchDetailsQuery(dispatchId);
  const [updateDispatchReg, { isLoading: loadingUpdate }] =
    useUpdateDispatchRegMutation();

  const [dispatchDetial, setDispatchDetial] = useState(null);

  useEffect(() => {
    if (data) {
      setDispatchDetial({
        id: data.id,
        challanNo: data.challanNo || "",
        challanDate:
          new Date(data.challanDate).toLocaleDateString("en-GB") || "",
        partyName: data.partyName || "",
        partyPlace: data.partyPlace || "",
        orderAmt: data.orderAmt || "",
        transportName: data.transportName || "",
        lrNumber: data.lrNumber || "",
        lrDate: data.lrDate || "",
        numberofCase: data.numberofCase || "",
        invoiceNumber: data.invoiceNumber || "",
        invoiceDate: data.invoiceDate || "",
        packingTime: data.packingTime || "",
        salesRep: data.salesRep || "",
        orderStatus: data.orderStatus || "",
        priority: data.priority || "",
        remarks: data.remarks || "",
        totalItems: data.totalItems || "",
        totalQty: data.totalQty || "",
      });
    }
  }, [data]);

  const updatetptHandler = async () => {
    const tptDetial = {
      id: dispatchDetial.id,
      transportName: dispatchDetial.transportName,
      lrNumber: dispatchDetial.lrNumber,
      lrDate: dispatchDetial.lrDate,
      numberofCase: dispatchDetial.numberofCase,
    };
    try {
      await updateDispatchReg(tptDetial).unwrap();

      toast.success("Transport Details Updated Successfully");
    } catch (err) {
      console.error(err);
      toast.error(err?.data?.message || err.error);
    }
  };

  const updateOrderStatusHandler = async () => {
    const statusDetial = {
      id: dispatchDetial.id,
      orderStatus: dispatchDetial.orderStatus,
      remarks: dispatchDetial.remarks,
      priority: dispatchDetial.priority,
    };

    try {
      const res = await updateDispatchReg(statusDetial).unwrap();
      console.log(res);
      toast.success("Order Status Updated Successfully");
    } catch (err) {
      console.error(err);
      toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <>
      <Link className="btn btn-light my-3" to="/dispatch">
        Go Back
      </Link>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : dispatchDetial ? (
        <Row>
          <Col md={3}>
            <ListGroup>
              <ListGroup.Item>
                <h5>Challan No: {dispatchDetial.challanNo}</h5>
                <p>
                  <strong>Date:</strong> {dispatchDetial.challanDate}
                </p>
                <p>
                  <strong>Party Name: </strong>{" "}
                </p>
                <p>
                  {`${dispatchDetial.partyName} ${dispatchDetial.partyPlace}`}
                </p>
                <p>
                  <strong>Order Detials</strong>
                  <ul>
                    <li>
                      <strong>Order Amount : </strong> {dispatchDetial.orderAmt}
                    </li>
                    <li>
                      <strong>Total Items : </strong>{" "}
                      {dispatchDetial.totalItems}
                    </li>
                    <li>
                      <strong>Total Qty : </strong> {dispatchDetial.totalQty}
                    </li>
                  </ul>
                </p>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={3}>
            <ListGroup>
              <ListGroup.Item>
                <h6>
                  <strong>Transport Detials</strong>
                </h6>
                <p>
                  <Form.Label>Transport Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Transport Name"
                    value={dispatchDetial.transportName}
                    onChange={(e) =>
                      setDispatchDetial({
                        ...dispatchDetial,
                        transportName: e.target.value,
                      })
                    }
                  />

                  <Form.Label>Number:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter LR Number"
                    value={dispatchDetial.lrNumber}
                    onChange={(e) => {
                      setDispatchDetial({
                        ...dispatchDetial,
                        lrNumber: e.target.value,
                      });
                    }}
                  />

                  <Form.Label>Date:</Form.Label>
                  <Form.Control
                    type="date"
                    value={
                      dispatchDetial.lrDate
                        ? new Date(dispatchDetial.lrDate)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={(e) => {
                      setDispatchDetial({
                        ...dispatchDetial,
                        lrDate: e.target.value,
                      });
                    }}
                  />

                  <Form.Label>Case:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Number of Case"
                    value={dispatchDetial.numberofCase}
                    onChange={(e) => {
                      setDispatchDetial({
                        ...dispatchDetial,
                        numberofCase: e.target.value,
                      });
                    }}
                  />
                </p>
                <div className="d-flex justify-content-end">
                  {loadingUpdate ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button
                      type="button"
                      variant="outline-primary"
                      onClick={updatetptHandler}
                    >
                      Update
                    </Button>
                  )}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={3}>
            <ListGroup>
              <ListGroup.Item>
                <p>
                  <strong>MR :</strong> {dispatchDetial.salesRep}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p>
                  <strong>Priority : </strong>{" "}
                  {dispatchDetial.priority ? (
                    <>
                      <Badge bg="danger" className="me-2">
                        Ugrent
                      </Badge>

                      <FaTrashAlt
                        onClick={() =>
                          setDispatchDetial({
                            ...dispatchDetial,
                            priority: !dispatchDetial.priority,
                          })
                        }
                        style={{ cursor: "pointer" }} // Add cursor: "pointer"
                      />
                    </>
                  ) : (
                    <Button
                      type="button"
                      variant="outline-primary"
                      onClick={() =>
                        setDispatchDetial({
                          ...dispatchDetial,
                          priority: !dispatchDetial.priority,
                        })
                      }
                    >
                      Mark Urgent
                    </Button>
                  )}
                </p>
                <p>
                  <strong>Order Status :</strong>
                  <Form.Control
                    as="select"
                    placeholder="Enter Number of Case"
                    value={dispatchDetial.orderStatus}
                    onChange={(e) => {
                      setDispatchDetial({
                        ...dispatchDetial,
                        orderStatus: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Order Status</option>
                    <option value="Printed">Printed</option>
                    <option value="Packed">Packed</option>
                    <option value="Dispatched">Dispatched</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </Form.Control>
                </p>
                <p>
                  <strong>Remarks : </strong>
                  <Form.Control
                    as="textarea"
                    rows={2} // Adjust the number of rows for the size of the box
                    placeholder="Enter your remarks here"
                    value={dispatchDetial.remarks}
                    onChange={(e) => {
                      setDispatchDetial({
                        ...dispatchDetial,
                        remarks: e.target.value,
                      });
                    }}
                  />
                </p>
                <div className="d-flex justify-content-end">
                  {loadingUpdate ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button
                      type="button"
                      variant="outline-primary"
                      onClick={updateOrderStatusHandler}
                    >
                      Update
                    </Button>
                  )}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      ) : (
        <Message variant="info">No Details Found</Message>
      )}
    </>
  );
};

export default DispatchDetailScreen;
