import { useState } from "react";

const useSerialPort = () => {
  const [serialData, setSerialData] = useState(""); // State to store incoming data
  const [connected, setConnected] = useState(false); // State for connection status

  // Function to initiate the serial port connection and read data
  const connectToSerialPort = async () => {
    try {
      // Request the user to select a serial port
      const port = await navigator.serial.requestPort();
      await port.open({ baudRate: 9600 }); // Set the baud rate as per your device

      setConnected(true);
      setSerialData((prev) => `${prev}Connected to Serial Port.\n`);

      // Setup TextDecoder to read the port's data stream
      const decoder = new TextDecoderStream();
      port.readable.pipeTo(decoder.writable);
      const reader = decoder.readable.getReader();

      // Read incoming data
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // Release the reader
          setConnected(false);
          setSerialData((prev) => `${prev}Disconnected from Serial Port.\n`);
          break;
        }
        //setSerialData((prev) => `${prev}${value}`); // Update state with incoming data
        setSerialData(value);
      }
    } catch (err) {
      console.error("Error:", err);
      //setSerialData((prev) => `${prev}Error: ${err.message}\n`);
      setSerialData(err.message);
    }
  };

  // Returning the connection function and state for reuse
  return {
    connectToSerialPort,
    serialData,
    connected,
  };
};

export default useSerialPort;
